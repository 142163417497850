/* --- Global Styles --- */
html, body, #root {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: auto;
  /* border: 2px dashed red; selects the entire page layout */
}

body {
  display: flex;
  flex-direction: column;
}


/* --- Table Styles --- */
table {
  width: 100%; /* Ensures the table spans the full width */
  border-collapse: collapse; /* Removes spacing between borders */
}

th, td {
  padding: 10px; /* Adjust padding for better spacing */
  text-align: left; /* Keeps text aligned left */
  vertical-align: top; /* Moves text to the top */
}

/* --- Logo Styling --- */
.navbar-brand {
  padding: 10px;
  /* border: 2px dashed pink; */
}

.navbar-brand img {
  max-width: 150px; /* Allow the image to scale up to the container's width */
  height: auto; /* Maintain the aspect ratio */
  padding: 5px; /* Ensure padding is uniform */
  object-fit: contain; /* Ensures the image scales properly */
}

/* --- Navbar Styles --- */
.navbar {
  margin: 0;
  padding: 0;
  width: 100%; /* Ensure full width */
  box-sizing: border-box;
  background-color: #3A2C86; /* Example background color */
  /* border: 2px dashed green; selects Nav Top and Union Intelligence to Resources */
}

.navbar .container {
  margin: 0;
  padding: 0 0px;
  width: 100%; /* Ensure full width */
  /* border: 2px dashed orange; selects Nav Top and Union Intelligence to Resources */
}

.nav-item {
  margin-left: 0px;
  /* border: 2px dashed salmon; */
}

/* Common font styling for navigation items */
.nav-item-font {
  color: #737070; /* Dropdown text coloring */
  font-weight: 400; /* Example font weight */
  font-size: 16px; /* Example font size */
}

.navbar-purple {
  background-color: #3A2C86;
  /* border: 2px dashed purple; no visible change */
}

/* --- Sub-Navigation Styles --- */
.subnav-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  white-space: nowrap;
  overflow: visible;
  /* padding: 2; */
  box-sizing: border-box;
  /* border: 2px dashed violet; SubNav container */
}

.navbar-gray {
  background-color: LightGray;
  /* border: 2px dashed gray; SubNav and extra grey space */
}

.subnav-navbar {
  background-color: #ffffff;
  /* border: 2px dashed cyan; SubNav Items only */
}

.subnav-links {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: left;
  /* padding: 5px; */
  gap: 0px;
  /* border: 2px dashed lime; SubNav Items Only*/
}

/* --- Dropdown Menu Styles --- */
.dropdown-menu {
  display: none;
  position: absolute;
  top: 100%;
  left: -20; /* moves the entire dropdown box to the right - 0 is furthest left */
  z-index: 1050;
  background-color: #d3d3d3 !important;
  /* border: 1px solid #000000; */
  padding-left: 5px; /* Only set the left padding */
  padding-top: 1px; /* Ensure other sides are handled explicitly */
  padding-bottom: 1px; 
  padding-right: 0; /* Optional, remove unnecessary right padding */
  /* box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); */
  /* border: 2px dashed brown; SubNav Dropdown Items */
}

.dropdown-menu.show {
  display: block;
  padding: 5px 1px; /* moves bounding box up */
  line-height: 1.0;
  opacity: 1;
  transform: translateY(0);
  /* border: 2px dashed magenta;  SubNav Dropdown Items */
}

/* Adjusting the dropdown items */
.dropdown-item {
  padding-left: 5px; /* Controls placement of Nav Items just right of the dropdown container */
  line-height: 1.5; /* Adjusts the line spacing */
}

.dropdown-item.active, 
.dropdown-item:active {
    background-color: inherit !important;  /* Change to any color you want */
    color: inherit !important; /* Keep text color unchanged */
}

.events-section {
  width: 100%; /* Ensure both tables take full width */
  min-width: 300px; /* Prevent them from shrinking too much */
  overflow-x: auto; /* Allows horizontal scrolling if necessary */
  flex: none;
}

/* --- PowerBI App Container --- */
.app-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
  /* border: 2px dashed coral; */
}

.report-style {
  width: 100%;
  height: 100%;  
  display: block;
  /* Ensure it fills the parent container */
}
/* --- Power BI Report Styling --- */
.powerbi-report-container {
  height: calc(100vh - 56px); /* Adjust for navbar */
  width: 100vw;
  margin: 0;
  padding: 0;
  position: absolute; /* Ensures the report stays fixed below the navbar */
  top: 56px; /* Pushes it right below the navbar */
  left: 0;
  right: 0;
  bottom: 0;
  /* border: 2px dashed yellow; */
}

.btn-purple {
  background-color: #3A2C86;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  /* border: 2px dashed navy; */
}

.btn-purple:hover {
  background-color: #2a216e;
  /* border: 2px dashed gold; */
}

/* --- Error and Loading Messages --- */
.error-message,
.loading-message {
  text-align: left;
  margin-top: 20px;
  color: red;
  /* border: 2px dashed black; */
}

.loading-message {
  color: #000;
  /* border: 2px dashed silver; */
}

/* Events Button Styles */
.button-same-width {
  width: 100px; /* Set a fixed width for the buttons */
  text-align: center;
}

.navbar-nav {
  padding-left: 10px; /* Adjust this value as needed */
}
/* --- Responsive Adjustments --- */

@media (min-width: 800px) {
  .navbar-expand-md .navbar-nav .nav-link {
      padding-right: 5px !important;
      padding-left: 5px !important;
  }
}

@media (max-width: 800px) {
  .powerbi-report-container {
    height: calc(100vh - 56px); /* Adjust the height calculation for smaller screens */
  }
  .navbar-collapse.show .navbar-nav {
    padding-left: 10px; /* Adjust this value as needed */
}
  .events-section {
    flex: none; /* Reset flex sizing */
    width: 100%; /* Full width */
  }
  .registered-events {
    margin-bottom: 20px; /* Extra spacing between tables */
  }
  .navbar .container {
      padding: 0 0px;
      border: 0px dashed olive; /* Entire Nav and SubNav section for pixel width up to 799 */
    }

    .navbar-brand img {
      max-width: 100px !important; /* Fixed width */
      height: auto !important; /* Maintain aspect ratio */
    }

  .nav-item {
    margin-left: 0px;
    /* border: 2px dashed salmon; */
  }

  .navbar-toggler {
    display: block; /* Ensure the toggler is displayed */
    border: 0px;
    color: #000000;
    /* padding: 8px 12px; */
    /* border: 2px dashed plum; */
  }

  .subnav-container {
    flex-wrap: nowrap;
    padding-left: 10px;
    /* border: 2px dashed maroon; */
  }

  .subnav-links {
    flex-wrap: nowrap;
    /* gap: 2px; */
    /* border: 2px dashed indigo; */
  }
  .powerBI-nav {
    flex-wrap: nowrap;
    /* gap: 2px; */
    /* border: 2px dashed blue; */
  }

  .navbar-collapse {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: left;
    /* gap: 2px; */
    /* border: 2px dashed lavender; Full Mobile SubNav Layout */
  }

  .navbar-collapse .subnav-link,
  .navbar-collapse .subnav-dropdown,
  .navbar-collapse .powerBI-nav {
    /* padding: 5px 5px; */
    width: 100%;
    flex-wrap: nowrap;
    /* border: 2px dashed beige; No visible control*/
  }

  .dropdown-menu {
    padding: 0px 0px;
    box-shadow: none !important;
    border: none;
    /* border: 2px dashed crimson; SubNav Dropdown*/
  }
  .navbar-brand img {
    max-width: 100px !important; /* Fixed width */
    height: auto !important; /* Fixed height */
    /* padding: 5px; /* Ensure padding is uniform */
    /* object-fit: contain; Ensures the image scales properly */
  }
}

/* --- Contact Button Styles --- */
.contact-button {
  background-color: #3A2C86;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  /* border: 2px dashed peachpuff; */
}

.contact-button:hover {
  background-color: #2a216e;
  /* border: 2px dashed turquoise; */
}

/* --- Help Button Styles --- */
.help-button {
  background-color: #FFC107;
  color: #000;
  border-radius: 5px;
  padding: 10px 20px;
  position: fixed;
  bottom: 20px;
  left: 80px; /* Move the button to the left */
  right: 0px;
  z-index: 1000;
  /* border: 2px dashed lightblue; */
}

.help-button:hover {
  background-color: #e0a806;
  /* border: 2px dashed aquamarine; */
}